<template>
	<section class="content">
		<div class="overlay-wrapper">
			<div class="card card-primary">
				<div class="overlay" v-if="loadingContent"><h1 style="font-size:40px;">Sedang diproses <i class="fas fa-sync-alt fa-spin"></i></h1></div>
				<div class="card-body">
					<div class="row">
						<div class="col-9">
							<div class="row form-horizontal">
								<div class="col-auto">
									<label class="control-label my-2 ml-2">
										<h6>Filter Pencarian</h6>
									</label>
								</div>
								<div class="col-auto mb-2">
									<select class="form-control" @change="dataType($event)">
										<option value="pickupTime">Dibuat Antara</option>
										<option value="updatedOn">Update Antara</option>
									</select>
								</div>
								<div class="col-auto mb-2">
									<div class="input-group">
										<input type="text" ref="daterange" class="form-control" style="border-right: 0" />
										<div class="input-group-append">
											<div class="input-group-text" style="background-color: #fff">
												<span><span class="fa fa-calendar"></span></span>
											</div>
										</div>
									</div>
								</div>
								<div class="col-auto mb-2">
									<button class="btn dropdown-icon  dropdown-toggle" v-bind:class="btnFilter"
										@click="selectStatus">Status </button>
								</div>
								<div class="col-auto mb-2">
									<select class="form-control" @change="orderType">
										<option value="">Tipe Order</option>
										<option value="1">Drop</option>
										<option value="0">Pickup</option>
									</select>
								</div>
							</div>
						</div>
						<div class="col-3 text-right">
							<div class="btn-group" ref="toolbar">
								<button type="button" data-action="read" class="btn btn-default"><i
										class="fa fa-sync-alt"></i></button>
								<button type="button" :disabled="downloading" v-on:click="downloadXlsx"
									data-action="export-xlsx" class="btn btn-default"><i class="fa fa-download"></i> <span class="d-none d-md-inline-block">Download</span></button>
							</div>
						</div>
					</div>
					<div class="table-responsivex">
						<div class="table-responsive">
							<table class="table table-hover" ref="tblarsipriwayat" id="tblarsipriwayat" width="100%">
								<thead>
									<tr>
										<th>No. Order</th>
										<th>No. Resi</th>
										<th>Pengirim</th>
										<th>Customer</th>
										<th>Tujuan</th>
										<th>Kurir</th>
										<th>Tanggal Pickup</th>
										<th>Status</th>
										<th>ID Shipper</th>
										<th>Shipper</th>
										<th>Service</th>
										<th>{{ this.userInfo.role_id == 1 || this.userInfo.role_id == 2 || this.userInfo.role_id == 3 || this.userInfo.role_id == 10 || this.userInfo.role_id == 6 || this.userInfo.role_id == 4?'Mark':'Tipe' }}</th>
									</tr>
								</thead>
								<tbody @click="handleClick">
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
		<Historyfilter :show="show" v-model="filter" v-on:change="updateFilter" />
	</section>
	<!-- /.content -->
</template>
<script>
import { createTable, authFetch, auth, openChat, charsRemover } from "@/libs/hxcore";
import Swal from "sweetalert2";
import $ from "jquery";
import Daterangepicker from "daterangepicker";
import "daterangepicker/daterangepicker.css";
import Historyfilter from "@/dialog/Historyfilter";
import FormClaim from "@/dialog/FormClaim";
import FormClosingClaim from "@/dialog/FormClosingClaim";
import moment from "moment";
import "icheck-material";
export default {
	name: "Riwayat",
	data() {
		return {
			dateStart: '',
			dateEnd: '',
			errors: [],
			groupId: '',
			downloading: false,
			show: false,
			filter: {
				dt1: moment("2021-01-01"),
				dt2: moment(),
			},
			data_type: "pickupTime",
			dt1: moment("2021-01-01"),
			dt2: moment(),
			ownloading: false,
			btnFilter: 'btn-default',
			processing: false,
			loadingContent: false,
		};
	},
	watch: {
		$route(to, from) {
			if (to.path === "/riwayat/admin/all") {
				sessionStorage.filterData = '';
				sessionStorage.filterStatus = '';
				this.btnFilter = 'btn-default';
				this.filter = {};
				this.table.api().ajax.reload();
				$('.loading-overlay').removeClass('show');
			}
		}
	},
	computed: {
		kurirLogo() {
			const fileName = "jne"; //logo.toLowerCase()

			return require(`../assets/img/${fileName}.png`); // the module request
		},
	},
	components: {
		Historyfilter
	},
	created: function () {
		this.roles = this.$route.meta.roles;
		this.userInfo = auth.user();
		if (this.$route.path === "/riwayat/admin/all") {
			sessionStorage.filterData = '';
			sessionStorage.filterStatus = '';
		}
		if (this.$route.params.filter)
			this.filter = this.$route.params.filter;
		if (this.filter.dt1 && this.filter.dt2) {
			this.dt1 = moment(this.filter.dt1);
			this.dt2 = moment(this.filter.dt2);
		}
		this.dateStart = this.dt1.format('YYYY-MM-DD');
		this.dateEnd = this.dt2.format('YYYY-MM-DD');
		if (this.filter.gid) {
			this.groupId = this.filter.gid;
			this.dateStart = '';
			this.dateEnd = '';
		}
		if (Object.keys(this.filter).length < 1) {
			if (sessionStorage.filterData) this.filter = JSON.parse(sessionStorage.filterData);
		} else {
			sessionStorage.filterData = JSON.stringify(this.filter);
			sessionStorage.filterStatus = 1;
		}
		this.filter.datatype = this.data_type;
		this.filter.dt1 = !this.filter.dt1 ? moment().startOf("month").format("YYYY-MM-DD") : this.filter.dt1;
		this.filter.dt2 = !this.filter.dt2 ? moment().format("YYYY-MM-DD") : this.filter.dt2;

		if (sessionStorage.filterStatus == 1) {
			this.btnFilter = 'btn-info';
		}
		
		authFetch("/users/groups")
			.then((res) => {
				if (res.status === 201) {} else if (res.status === 400) {}
				return res.json();
			})
			.then((js) => {
				this.groups = js.data;
			});
	},
	methods: {
		infoClaim(claim){
			var statusClaim = '';
			if (claim == 1) {
			  statusClaim = '<span class="badge badge-info">Claim diproses</span>';
			} else if (claim == 2) {
			  statusClaim = '<span class="badge badge-success">Claim disetujui</span>';
			} else if (claim == 3) {
			  statusClaim = '<span class="badge badge-danger">Claim ditolak</span>';
			} else if (claim == 4) {
			  statusClaim = '<span class="badge badge-warning">Claim dibanding</span>';
			} else if (claim == 5) {
			  statusClaim = '<span class="badge bg-indigo">Claim void</span>';
			}
			return statusClaim;
		},
		infoStatus(track) 
		{
			var output = '';
			var ongkir = '';

			if (track.updated == 1){
				ongkir += ' <span class="badge" style="color: #F86F15; background-color: #FFD68A"><i class="fi fi-rr-triangle-warning justify-content-center align-items-center" bold></i> Perubahan Ongkir</span>'
			}

			if (track.cancel == 1)
			{
				output += '<span class="badge badge-danger">Cancel</span>';
			}
			else if (track.error_message)
			{
				output += '<span class="badge badge-danger">Error</span>';
			}
			else if (track.pickupStatus == 0)
			{
				output += '<span class="badge badge-inverse">DRAFT</span>';
			}
			else if (track.orderStatus == 2)
			{
				output += '<span class="badge bg-indigo">Void</span>';
			}
			else
			{
				if (track.trackingStatus == 0)
				{
					if(track.order_type == 1){
						output += '<span class="badge badge-secondary">Menunggu Drop</span>';
					} else {
						output += '<span class="badge badge-secondary">Menunggu Pickup</span>';
					}
				}
				else if (track.trackingStatus == 1)
				{
					output += '<span class="badge badge-warning">Proses</span>';
				}
				else if (track.trackingStatus == 2)
				{
					output += '<span class="badge badge-success">Terkirim</span>';
				}
				else if (track.trackingStatus == 3)
				{
					output += '<span class="badge badge-warning">Proses Retur</span>';
				}
				else if (track.trackingStatus == 4) {
					if (track.retStatus == 1) 
					{
						output += '<span class="badge badge-success">Retur Diterima</span>';
					}
					else if (!track.retStatus)
					{
						output += '<span class="badge badge-info">Retur Diserahkan</span>';
					}
				}
				else if (track.trackingStatus == 5) 
				{
					if (track.problem_description != null){
						output += '<span class="badge badge-danger">Bermasalah - '+track.problem_description+'</span>';
					} else {
						output += '<span class="badge badge-danger">Bermasalah</span>';
					}
				}
				output += this.infoClaim(track.retClaim);

				if (track.special_claim > 0) {
					output += '<span style="border-width: thin;border-style: solid;" class="badge border-' + (track.special_claim == 1 ? 'info text-info">Request' : track.special_claim == 2 ? 'success text-success">Approved' : track.special_claim == 3 ? 'danger text-danger">Rejected' : 'warning text-warning">Unknown') + ' Special Claim</span>';
				}
			}
			// var output = '';
			// output += this.statusTracking;
			// output += this.statusClaim;
			return output+ongkir;
		},
		downloadXlsx: function (e) {
			// console.log($("select[name=category]").val());
			// console.log($("input[name=q]").val());
			// let countDate = this.dateDiff(this.filter.dt1, this.filter.dt2);
			this.downloading = true;
			var table = $('#tblarsipriwayat').DataTable();
			var length = table.page.info().recordsTotal;
			
			if (length > 20000) {
				Swal.fire("Proses gagal", `Maksimal data AWB sebanyak kurang lebih 20.000 data.`, "error");
				this.downloading = false;
				return false;
			}
			
			var filter = JSON.parse(JSON.stringify(this.filter));
				filter.q = $("input[name=q]").val();
				filter.category = $("select[name=category]").val();

			console.log(filter);
			var data = Object.keys(filter)
				.map(
					(key) =>
					encodeURIComponent(key) + "=" + (filter[key] === false ? 0 : encodeURIComponent(filter[key]))
				)
				.join("&");
			console.log(data)
			this.loadingContent = true;
			authFetch("/report/order/arsip_excell_admin", {
					method: "POST",
					body: data,
				})
				.then((response) => {
					if (response.status === 201) {
					} else if (response.status === 400) {
					} else if (response.status === 500){
						Swal.fire("Timeout!", ``, "error");
						this.loading2 = false;
						this.msg = '';
						return false;
					}

					return response.blob();
				})
				.then((blob) => {
					setTimeout(() => {
						this.downloading = false;
					}, 1000);
					this.loadingContent = false;
					var url = window.URL.createObjectURL(blob);
					var a = document.createElement("a");
					a.href = url;
					a.download = "Arsip Riwayat Kiriman Admin.xlsx";
					document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
					a.click();
					a.remove(); //afterwards we remove the element again
				});
		},
		updateFilter: function (e) {
			this.filter = e;
			this.table.api().ajax.reload();
		},
		dataType(event) {
			this.data_type = event.target.value;
			this.filter.datatype = this.data_type;
			this.table.api().ajax.reload();
		},
		orderType(event) {
			this.filter.order_type = event.target.value;
			this.table.api().ajax.reload();
		},
		onDate() {
			this.table.api().ajax.reload();
		},
		selectStatus: function () {
			this.show = !this.show;
		},
		getKurirLogo(kurir) {
			if (!kurir) return;
			const fileName = kurir.toLowerCase();
			return require(`../assets/img/${fileName}.png`); // the module request
		},
		dateDiff(dt1, dt2){
			let d1 = new Date(dt1);
			let d2 = new Date(dt2);

			const difference = d2.getTime() - d1.getTime();
			const days = Math.round(difference / (1000 * 60 * 60 * 24));
			return days;
		},
		handleClick(e) {
			if (e.target.matches(".link-order")) {
				let route = this.$router.resolve({
					path: "/arsip/detail/" + e.target.dataset.id
				});
				window.open(route.href,"_blank");
			} else if (e.target.matches(".link-chat")) {
				openChat(e.target.dataset.to);
				return false;
			}
		},
	},
	mounted() {
		this.$gtag.event(`page_view`, {
			'event_name': 'Riwayat Kiriman'
		});
		const e = this.$refs;
		const self = this;

		new Daterangepicker(
			this.$refs.daterange, {
				startDate: this.dt1,
				endDate: this.dt2,
				locale: {
					format: 'DD/MM/YYYY'
				},
			},
			function (d1, d2) {
				self.dateStart = d1.format("YYYY-MM-DD");
				self.dateEnd = d2.format("YYYY-MM-DD");
				self.data_type = self.data_type;
				self.filter.datatype = self.data_type;
				self.filter.dt1 = self.dateStart;
				self.filter.dt2 = self.dateEnd;
				self.onDate();
			}
		);
		var role_id =  this.userInfo.role_id;
		var filterBy = [0, 1, 2, 3, 4, 5, 10];// 8, 9,
		this.table = createTable(e.tblarsipriwayat, {
			title: "Data User",
			// scrollX: true,
			roles: this.$route.params.roles,
			ajax: "/order/arsip_riwayat_all",
			frame: false,
			toolbarEl: e.toolbar,
			lengthChange: true,
			filter: true,
			stateSave: true,
			dom: "<'row'<'col-sm-12 col-md-6 btn-area-selected'><'col-sm-12 col-md-6'f>>" +
				"<'row'<'col-sm-12'tr>>" +
				"<'row'<'col-sm-12 col-md-2'l><'col-sm-12 col-md-3'i><'col-sm-12 col-md-7'p>>",
			processing: true,
			language: {
				processing: '<i class="fa fa-spinner fa-5x fa-spin" style="font-size:50px;color:black;"></i>'
			},
			filterBy: filterBy,
			paramData: function (d) {
				var filter = JSON.parse(JSON.stringify(self.filter));
				//d = filter;
				//d.dev = filter.delivered;
				for (const key in filter) {
					d[key] = filter[key] === false ? 0 : filter[key];
				}
			},
			"order": [
				[6, 'desc'],
				[1, 'desc']
			],
			columns: [{
					data: "order_code",
					render: function (data, type, row, meta) {
						return (`<span class="link link-order text-info" style="cursor:pointer;" data-id="${row.order_no}">${row.order_code}</span>`);
					},
				},
				{
					data: "billNo"
				},
				{
					data: "gudang",
					title: "Asal Gudang",
					render: function (data, type, row, meta) {
						return data;
					},
				},
				{
					data: "destName",
					visible: true,
					render: function (data, type, row, meta) {
						return (
							'<a class="link link-chat text-info" style="cursor:pointer;" data-to="' +
							row.destTel +
							'">' +
							data +
							"</a>"
						);
					},
				},
				{
					data: "destCity",
					render: function (data, type, row, meta) {
						return row.kecamatan + ", " + row.destCity;
					},
				},
				{
					data: "courierCode",
					render: function (data, type, row, meta) {
						if (!data) return '';
						var logo = data ? data.toLowerCase() : '';
						return '<img height="16" src="' + self.getKurirLogo(logo) + '" /><small class="d-block text-center">' + row.service_code + '</small>';
					},
				},
				{
					data: "pickupTime",
					render: function (data, type, row, meta) {
						return data;
					},
				},
				{
					data: "pickupStatus",
					render: function (data, type, row, meta) {
						var badge = '';
						badge = self.infoStatus(row);
						return badge;
					},
				},
				{
					data: null,// "shipperCode",
					visible: false,
				},
				{
					data: "shipperName",
					visible: false,
					render: function (data, type, row, meta) {
						return (
							'<a class="link link-chat text-info" style="cursor:pointer;" data-to="' +
							row.senderPhone + '">' + data + "</a>"
						);
					},
				},
				{
					data: "service_code",
					visible: false,
				},
				{
					sortable: false,
					data: role_id == 1 || role_id == 2 || role_id == 3 || role_id == 10 || role_id == 6 || role_id == 4?"mark":"order_type",
					visible: false,
					render: function (data, type, row, meta) {
						if (role_id == 1 || role_id == 2 || role_id == 3 || role_id == 10 || role_id == 4) {
							return data;
						}
						else{
							if (data == 0) {
								return '<span class="badge badge-info">Pickup</span>';
							} else if (data == 1) {
								return '<span class="badge badge-warning">Drop</span>';
							} else {
								return "";
							}
						}
					},
				},
			],
			createdRow: function (row, data, dataIndex) {
				if (data['special_claim'] == 1) {
					$(row).css('background-color', '#F7EBEB');
				}
			},
			initComplete: function () {
				$('.loading-overlay').removeClass('show');
			},
		});
	},
};
</script>
<style scoped>
.dataTables_length{padding-top: 0.5em;}
</style>